import { CSSProperties } from 'react';

import styles from './Loader.module.scss';

type TpLoaderProps = {
  size?: number;
};
export const Loader = (props: TpLoaderProps) => {
  const { size = 50 } = props;
  return (
    <div className={styles.container}>
      <div
        className={styles.loader}
        style={{ '--loader-size': `${size}px` } as CSSProperties}
      />
    </div>
  );
};
